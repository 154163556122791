/** @jsxImportSource theme-ui */
import React, { useState } from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import * as prismicH from "@prismicio/helpers";
import { PrismicRichText } from "@prismicio/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Modal from "react-modal";
import { FaArrowRight, FaTimes } from "react-icons/fa";
import SEO from "../components/Seo";
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";
import LightGallery from "lightgallery/react";

// import styles
import "lightgallery/css/lightgallery.css";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "transparent",
    border: "none",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.90)",
    zIndex: 20,
  },
};
// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#___gatsby");

// markup
const CorporatePage = ({ data }) => {
  const pageData = data.prismicCorporatePage.data;

  const [modalState, setModalState] = useState(false);

  const [modalIndex, setModalIndex] = useState(0); //index of headshot

  const closeModal = () => {
    setModalState(false);
  };
  const onInit = () => {
    console.log("lightGallery has been initialized");
  };

  return (
    <Layout>
      <SEO
        title="Corporate Headshots"
        description="London corporate headshot and portrait photographer based in Greenwich.  Professional headshots for business, LinkedIn, CV's"
      />
      <div>
        <div
          sx={{
            fontSize: 4,
            color: "muted",
            h1: {
              color: "black",
            },
          }}
        >
          <h1>{pageData.title.text}</h1>
          <PrismicRichText field={pageData.text.raw} />
        </div>
        <div
          className="portfolioGrid"
          sx={{
            //had to change it to inside LightGallery
            "& .lg-react-element": {
              display: "grid",
              gridTemplateColumns: [
                "repeat(2, 1fr)",
                "repeat(3, 1fr)",
                "repeat(4, 1fr)",
              ],
              gridGap: 4,
              my: 4,
            },
          }}
        >
          <LightGallery onInit={onInit} speed={500} download={false}>
            {pageData.gallery.map((item, index) => {
              const image = getImage(item.image);
              return (
                <figure
                  key={`portfolioItem${index}`}
                  data-src={item.image.url}
                  sx={headshotStyles}
                  // onClick={(e) => openModal(item)}
                >
                  <GatsbyImage
                    image={image}
                    alt={item.image.alt}
                    sx={{
                      "& img": {
                        border: "1px solid black",
                        objectFit: "cover",
                      },
                    }}
                  />
                  {/* <figcaption>{item.name.text}</figcaption> */}
                </figure>
              );
            })}
          </LightGallery>
        </div>
        <Modal
          isOpen={modalState}
          onRequestClose={closeModal}
          shouldCloseOnOverlayClick={true}
          style={modalStyles}
          contentLabel="Image Lightbox Modal"
        >
          <div
            sx={{
              color: "white",
              display: "flex",
              fontSize: "1.25rem",
              justifyContent: "right",
              cursor: "pointer",
              // marginRight: "2em",
              // marginBottom: "0.3em",
            }}
            onClick={closeModal}
          >
            <FaTimes />
          </div>
          <div
            sx={{
              //width: "1000px",
              maxWidth: "90vw",
              maxHeight: "90vh",
              textAlign: "center",
              "& .gatsby-image-wrapper": {
                width: "100%",
                maxWidth: "95vw",
                height: "100%",
                maxHeight: "85vh",
              },
              "& img": {
                //border: "1px solid black",
                objectFit: "contain !important",
              },
            }}
          >
            <GatsbyImage image={getImage(pageData.gallery[modalIndex].image)} />
          </div>

          {/* 
          //no name or rollover for corporate
          <div
            sx={{
              display: "flex",
              pt: 3,
              justifyContent: "center",
              h2: { bg: "white", color: "black", m: 0, p: 2, lineHeight: 1 },
              a: {
                color: "white",
                fontSize: 5,
                ml: 2,
                lineHeight: 1,
                "&:hover, &:focus-within": { color: "muted" },
              },
              svg: { marginTop: "4px" },
            }}
          >
            <h2>{modalName.text}</h2>
            {!(modalLink.link_type === "Any") && (
              <a href="#">{<FaArrowRight />}</a>
            )}
          </div> */}
        </Modal>
      </div>
    </Layout>
  );
};

export default withPrismicPreview(CorporatePage);

export const query = graphql`
  query corporatePageQuery {
    prismicCorporatePage {
      _previewable
      data {
        gallery {
          image {
            alt
            gatsbyImageData
            url
          }
        }
        text {
          richText
          text
          raw
        }
        title {
          raw
          richText
          text
        }
      }
      last_publication_date
    }
  }
`;

const headshotStyles = {
  m: 0,

  figcaption: {
    fontSize: "1.25rem",
  },
  "@media (hover: hover) and (pointer: fine)": {
    position: "relative",
    transition: "all 0.3s ease",
    cursor: "pointer",
    figcaption: {
      fontSize: "1.4rem",
      fontWeight: "400",
      position: "absolute",
      bottom: 2,
      left: 2,
      zIndex: 5,
      bg: "white",
      px: 2,
      py: 2,
      transition: "all 0.3s ease",
      transform: "translate(0,30px)",
      opacity: 0,
    },
    //hover
    "&:hover, &:focus-within": {
      figcaption: {
        transform: "translate(0,0)",
        opacity: 1,
      },
    },
  },
};
